<template>
  <v-container class="form-newsletter pa-md-12">
    <v-form ref="formRef" v-model="valid" lazy-validation v-if="form">
      <h3 class="secondary--text text-center mb-2">
        ISCRIVITI ALLA NOSTRA NEWSLETTER
      </h3>
      <p class="secondary--text text--grey-lighten-1 text-center">
        Riceverai in anteprima via e-mail i volantini con le nostre offerte,
        oltre a interessanti iniziative commerciali, il tutto con una frequenza
        moderata e nel rispetto della tua privacy.
      </p>
      <v-row no-gutters class="mt-8 px-md-12">
        <!-- 
          nome
          <v-col cols="12" sm="6">
          <v-text-field
            v-model="form.fields['9'].value"
            :label="form.fields['9'].label"
            :placeholder="form.fields['9'].placeholder"
            :rules="[requiredRule()]"
            class="px-4"
            outlined
            required
            dense
          >
          </v-text-field>
        </v-col>
        cognome
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['10'].label"
            :placeholder="form.fields['10'].placeholder"
            v-model="form.fields['10'].value"
            outlined
            :rules="[requiredRule()]"
            required
            dense
            class="px-4"
          >
          </v-text-field>
        </v-col> -->
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['8'].label"
            :placeholder="form.fields['8'].placeholder"
            v-model="form.fields['8'].value"
            outlined
            :rules="emailRules"
            required
            dense
            class="px-4"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['27'].label"
            :placeholder="form.fields['27'].placeholder"
            v-model="form.fields['27'].value"
            outlined
            :rules="[requiredRule()]"
            required
            dense
            class="px-4"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['28'].label"
            :placeholder="form.fields['28'].placeholder"
            v-model="form.fields['28'].value"
            outlined
            :rules="[requiredRule()]"
            required
            dense
            class="px-4"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['29'].label"
            :placeholder="form.fields['29'].placeholder"
            v-model="form.fields['29'].value"
            outlined
            :rules="[requiredRule()]"
            required
            dense
            class="px-4"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-radio-group
            v-model="form.fields['32'].value"
            row
            :label="form.fields['32'].label"
            :rules="[requiredRule()]"
            class="mt-0 px-4"
            required
            dense
          >
            <v-radio
              v-for="option in form.fields['32'].options"
              :key="option.value"
              :label="option.label"
              :value="option.label"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :label="form.fields['33'].label"
            :placeholder="form.fields['33'].placeholder"
            :required="form.fields['32'].value == 'si'"
            v-model="form.fields['33'].value"
            outlined
            dense
            class="px-4"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <div class="legal-agreement px-4">
        <!-- informativa privacy -->
        <span
          @click="clickAndCloseModal($event)"
          v-html="form.fields['11'].desc_text"
        ></span>
        <v-checkbox
          :label="form.fields['11'].help_text"
          v-model="form.fields['11'].value"
          outlined
          :rules="[requiredRule()]"
          required
          dense
          class="mt-0"
        >
        </v-checkbox>
        <!-- <div v-html="form.fields['34'].desc_text"></div>
        <v-radio-group
          v-model="form.fields['34'].value"
          row
          :rules="[requiredRule()]"
          required
          dense
          class="mt-0 pt-0"
        >
          <v-radio
            v-for="option in form.fields['34'].options"
            :key="option.value"
            :label="option.label"
            :value="option.label"
          ></v-radio>
        </v-radio-group> -->
        <!-- <div v-html="form.fields['35'].desc_text"></div>
        <v-radio-group
          v-model="form.fields['35'].value"
          row
          :rules="[requiredRule()]"
          required
          dense
          class="mt-0"
        >
          <v-radio
            v-for="option in form.fields['35'].options"
            :key="option.value"
            :label="option.label"
            :value="option.label"
          ></v-radio>
        </v-radio-group> -->
      </div>
      <div class="d-flex align-center justify-center">
        <v-btn
          color="primary"
          depressed
          large
          width="210"
          v-model="form.fields['12'].value"
          @click="handleSubmit"
        >
          {{ form.fields["12"].label }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>
<style lang="scss">
// .consensi {
//   font-size: 13px;
//   line-height: 150%;
//   :last-child {
//     padding-bottom: 0px !important;
//   }
//   label {
//     font-size: 13px !important;
//     line-height: 150% !important;
//   }
// }
</style>
<script>
import CMService from "~/service/cmService";

import clickHandler from "~/mixins/clickHandler";
import { mapGetters } from "vuex";
import { requiredValue, isEmail } from "~/validator/validationRules";
export default {
  data() {
    return {
      form: null,
      valid: true,
      requiredRule: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()]
    };
  },
  mixins: [clickHandler],
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    })
  },
  methods: {
    async fetchForm(formId) {
      this.form = await CMService.getForm(formId);
      if (this.isAuthenticated) {
        // this.form.fields["9"].value = this.user.firstName;
        // this.form.fields["10"].value = this.user.lastName;
        this.form.fields["8"].value = this.user.email;
      }
    },
    async handleSubmit() {
      if (this.$refs.formRef.validate()) {
        let result = await CMService.sendForm(this.form);
        if (result) {
          this.$emit("submit", true);
        }
      }
    },
    clickAndCloseModal(e) {
      this.clicked(e);
      this.$emit("submit", false);
    }
  },
  mounted() {
    this.fetchForm("3");
  }
};
</script>
