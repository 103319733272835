<template>
  <div class="secondary text--white">
    <v-container @click="clicked" class="px-0 px-sm-3 py-0">
      <!-- SOCIAL -->
      <v-row no-gutters class="social">
        <v-col
          cols="12"
          sm="6"
          class="social-links align-center d-flex flex-column flex-sm-row justify-center"
        >
          <span class="text--white text-center text-md-left mr-md-4">
            {{ $t("footer.social.followUs") }}
          </span>
          <span class="d-flex justify-center align-center mt-4 mt-sm-0">
            <a href="https://www.facebook.com/GrosMarketCashandCarry/">
              <img
                src="/footer/facebook.svg"
                class="mx-4 mx-sm-2"
                height="30"
                width="30"
                contain
              />
            </a>
            <!-- Logo Instagram -->
            <a href="https://www.instagram.com/grosmarket_official/">
              <img
                src="/footer/instagram.svg"
                class="mx-4 mx-sm-2"
                height="30"
                width="30"
                contain
              />
            </a>
            <!-- Logo Youtube -->
            <a href="https://www.youtube.com/user/sogegrosscash">
              <img
                src="/footer/youtube.svg"
                class="mx-4 mx-sm-2"
                height="30"
                width="30"
                contain
              />
            </a>
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="secondary darken-1  align-center d-flex flex-column flex-sm-row justify-center px-4"
        >
          <span class="text--white text-center text-md-left mr-md-4">
            {{ $t("footer.social.subscribe") }}
          </span>
          <v-btn
            color="primary"
            elevation="0"
            class="ml-0 ml-md-4 mt-5 mt-sm-0"
            @click="openModal"
          >
            {{ $t("footer.social.subscribeBtn") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.social {
  color: $white;
  font-size: 22px;
  line-height: 1;
  font-weight: bold;
  height: 129px;
  h3 {
    color: $white;
  }
  .v-btn {
    width: 132px;
    height: 40px;
    font-size: 12px;
    border-radius: $border-radius-root;
  }
  .newsletter-subscribe {
    height: 100%;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 400px;
    .newsletter-subscribe {
      height: 50%;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-only')} {
    padding: 0;
  }
}
</style>
<script>
import FormNewsletter from "@/components/wordpress/FormNewsletter";
import clickHandler from "~/mixins/clickHandler";
import login from "~/mixins/login";

export default {
  name: "NewsletterHorizontal",
  props: ["links"],
  mixins: [clickHandler, login],
  data() {
    return { modalOpen: false, dialogInstance: null };
  },
  methods: {
    async openModal() {
      this.dialogInstance = await this.$dialog.show(FormNewsletter, {
        width: "1100px"
      });
      const result = await this.dialogInstance.wait();
      if (result) {
        this.$emit("submit", true);
      }
    }
  }
};
</script>
